import axios from '@/config/axios'

// 我的设备状态统计
export const getDeviceStatus = () => axios({
  method: 'get',
  url: `/mobile/devices/status`
})

// 系统枚举数据
export const getEnums = (id) => axios({
  method: 'get',
  url: `/mobile/dict/${id}`,
})

// 区域树数据
export const getLocatioinTrees = () => axios({
  method: 'get',
  url: `/mobile/locationTrees`
})

// 设备列表
export const getDeviceList = (params) => axios({
  method: 'get',
  url: `/mobile/devices`,
  params
})

// 关注/取消关注设备
export const getChangeFollow = (id) => axios({
  method: 'get',
  url: `/mobile/follow/${id}`,
})



// 设备分组的查询接口
export const loadGroups = () => axios({
  method: 'get',
  url: `/deviceGroup/loadGroups`,
})


// 设备分组的查询接口
export const deviceDictType = (type) => axios({
  method: 'get',
  url: `/deviceDict/${type}`,
})

